import React from "react";

const ScrollUpButton = () => {
    const handlerClick = () => {
        window.scrollTo({
            left: 0,
            top: 0,
            behavior: 'smooth'
          });  
    };
    return (
        <div className="scroll-up-button" onClick={() => handlerClick()}>
            <span></span>
        </div>
    );
}

export default ScrollUpButton;