import Institution1 from './Institution-1.png';
import Institution2 from './Institution-2.png';
import Institution3 from './Institution-3.png';
import Institution4 from './Institution-4.png';
import Institution5 from './Institution-5.png';
import Institution6 from './Institution-6.png';
import Institution7 from './Institution-7.png';
import Institution8 from './Institution-8.png';

export const institutionImage1 = Institution1;
export const institutionImage2 = Institution2;
export const institutionImage3 = Institution3;
export const institutionImage4 = Institution4;
export const institutionImage5 = Institution5;
export const institutionImage6 = Institution6;
export const institutionImage7 = Institution7;
export const institutionImage8 = Institution8;