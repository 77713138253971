import React, { useContext } from "react";
import Context from "../../../Context";

const Books = () => {
    const contextValue = useContext(Context);
    return (
        <div ref={contextValue.booksSection} id="books" className="books-section">
            <div className="book-wrap">
                <div className="container books-container">
                    <h2>Книги</h2>
                    <a href="https://renewal.org.ua/#books" target="_blank">Замовити книги</a>
                </div>
            </div>
        </div>
    );
}

export default Books;